
// 
// breadcrumb.scss
//


// Breadcrumb item arrow
.breadcrumb-item {
    a {
        color: var(--#{$prefix}tertiary-color) ;
    }
    &.active{
        color: var(--#{$prefix}secondary-color);
    }
    +.breadcrumb-item {
        &::before {
            font-family: "Material Design Icons";
        }
    }
}
