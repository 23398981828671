/* =============
   General
============= */

html {
    position: relative;
    min-height: 100%;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--#{$prefix}secondary-color);
    font-weight: 600;
    font-family: $font-family-secondary;
}

a {
    text-decoration: none !important;
}

label {
    font-weight: 500;
    margin-bottom: $form-label-margin-bottom;
}

// Font weight help class

.ff-primary {
    font-family: $font-family-base;
}
  
.ff-secondary {
    font-family: $font-family-secondary;
}

// Small

.small,
small {
    font-weight: 400;
}

// blockquote

.blockquote {
    padding: 10px 20px;
    border-left: 4px solid var(--#{$prefix}border-color);
}

.blockquote-reverse {
    border-left: 0;
    border-right: 4px solid var(--#{$prefix}border-color);
    text-align: right;
}

.blockpuote-dark{
    background-color: rgba(var(--#{$prefix}dark-rgb), 0.15) !important;
    border-color: var(--#{$prefix}dark) !important;
    color: var(--#{$prefix}body-color) !important;
    .blockquote-footer{
        color: var(--#{$prefix}body-color) !important;
    }
}

// container

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
    @media (min-width: 1200px) {
        max-width: 1140px;
    }
}

// row

.row {
    >* {
        position: relative;
    }
}

// Icon size
.icon {
    position: relative;
}

.icon-xxs {
    height: 14px;
    width: 14px;
}

.icon-xs {
    height: 16px;
    width: 16px;
}

.icon-sm {
    height: 20px;
    width: 20px;
}

.icon-lg {
    height: 32px;
    width: 32px;
}

.icon-xl {
    height: 46px;
    width: 46px;
}

.icon-xxl {
    height: 62px;
    width: 62px;
}

.sw-3 {
    stroke-width: 3px;
}

.sw-1_5 {
    stroke-width: 1.5px;
}

.sw-1 {
    stroke-width: 1px;
}

// icon color
@each $color,
$value in $theme-colors {
    .icon-dual-#{$color} {
        color: $value;
        fill: rgba($value, 0.16);
    }

    .icon-fill-#{$color} {
        fill: $value !important;
    }
}


// Custom Blockquote

.custom-blockpuote {
    &.blockquote {
        padding: 20px 20px 20px 30px;
        border-left: 3px solid;
        font-size: 16px;
    }
}

@each $color,
$value in $theme-colors {
    .custom-blockpuote {
        &.blockquote {
            &.blockpuote-#{$color} {
                color: #{$value};
                border-color: #{$value};
                background-color: rgba($value, 0.15);

                .blockquote-footer {
                    color: #{$value};
                }
            }

            &.blockpuote-outline-#{$color} {
                border: 1px solid #{$value};
                border-left: 4px solid #{$value};

                .blockquote-footer {
                    color: #{$value};
                }
            }
        }
    }
}